@import "minima";

.site-header {
  border-top: 5px solid #b80000;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;

  // Positioning context for the mobile navigation icon
  position: relative;
}

.site-logo {
  padding: 8px 2px;
  height: 56px;
}

.footer-col-1 {
  width: -webkit-calc(25% - (#{$spacing-unit} / 2));
  width:         calc(25% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(30% - (#{$spacing-unit} / 2));
  width:         calc(30% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
  width: -webkit-calc(45% - (#{$spacing-unit} / 2));
  width:         calc(45% - (#{$spacing-unit} / 2));
}

.grid {
    display: grid;
    grid-template-columns: 200px 200px 200px;
    grid-template-rows: auto;
}

@media only screen and (max-width: 600px) {
    .grid {
      grid-template-columns: 200px 200px;
    }
}

@media only screen and (max-width: 320px) {
    .grid {
      grid-template-columns: 200px;
    }
}

.grid li {
  list-style-type: none;
  margin: 0px auto;
  padding: 10px;
  justify-self: center;
  align-self: center;
}

.meetings-list {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}


.meeting-date {
  font-style: italic;
  display: inline-block;
  float: right;
}

.meeting-title {
  display: inline-block;
}
